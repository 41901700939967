<template>
  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="footer-left">
        <div class="footer-item">
          <img src="~common/images/top_logo.png" alt="" class="foot-img" />
        </div>

        <!-- <div class="footer-item">
          <p class="title">支持</p>
          <p class="desc" @click="tapInfo">使用教程</p>
        </div> -->
        <div class="footer-item" style="padding-left: 0.6rem;">
          <p class="title" >友情链接</p>
          <a href="http://www.greatdata.com.cn" target="blank" style="cursor: pointer"> <p class="desc">数码通官网</p></a>
            <a href="https://smtyxy.com" target="blank" style="cursor: pointer"> <p class="desc">营销云服务平台</p></a>
        </div>
        <div class="footer-item" style="padding-left: 0.6rem;">
          <p class="title">联系我们</p>
          <p class="desc" @mouseenter="showTip=true" @mouseleave="showTip=false">购买咨询</p>
          <p class="desc" @mouseenter="showTip=true" @mouseleave="showTip=false">商务合作</p>
          
        </div>
        <div class="footer-item" style="padding-left: 0.6rem;">
          <p class="title">平台协议</p>
          <p class="desc" @click="tapUserAgreement">服务条款</p>
          <p class="desc" @click="tapPrivacyAgreement">隐私条款</p>
        </div>
      </div>
      

    </div>
    <p class="fotter-bottom">gzwy.cc  All Rights Reserved <a href="https://beian.miit.gov.cn/" style="cursor: pointer">京ICP备19054033号-15</a></p>
    <img v-show="showTip" src="~common/images/serve_tip.png" alt="" class="serve-tip-img" />
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    data() {
      return {
        showTip: false
      }
    },
    methods: {
      tapInfo() {
        this.$router.push({
          name: 'info'
        })
      },
      tapPrivacyAgreement() {
        this.$router.push({
          name: 'privacyAgreement'
        })
      },
      tapUserAgreement() {
        this.$router.push({
          name: 'userAgreement'
        })
      }
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .footer-wrapper
    width 100%
    background #F6F6F6
    padding-top .44rem
    position: relative;
    .footer-container
      width 100%
      color #000
      .footer-left
        display inline-block
        overflow hidden
        margin-left: 1.89rem
        padding-bottom: 0.49rem
      .footer-item
        display inline-block
        float left
        margin-left: 0.36rem
        .foot-img
          width: 0.54rem
        .title
          display inline-block
          font-size: 0.09rem;
          font-weight: bold;
          letter-spacing: 1px;
          color: #000000;
          padding-bottom: 0.08rem
        .desc
          cursor pointer
          font-size: 0.08rem;
          letter-spacing: 1px;
          color: #666666;
          margin-top: 0.07rem
          .gdt-code
            width .45rem
            margin-top -0.06rem
            margin-left: 0.18rem
    .fotter-bottom
      font-size: 0.08rem;
      font-weight: 500;
      color: #666666;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 0.8rem
      padding-bottom: 0.3rem
    .serve-tip-img
      position absolute
      bottom: 0.9rem;
      left: 5.55rem
      width: 1.24rem
</style>
